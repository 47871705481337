import React from 'react'
import styled from 'styled-components'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import color from '../constants/colors'
import { Section, InnerMedium } from '../components/Sections'

const MainMessage = styled.h1`
  color: ${color.heading};
  text-align: center;
`
const SubMessage = styled.p`
  color: ${color.text};
  text-align: center;
`

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Section>
      <InnerMedium>
        <MainMessage>
          404 Not Found
        </MainMessage>
        <SubMessage>
          申し訳ありませんが、お探しのページが見つかりませんでした。
        </SubMessage>
        <SubMessage>
          お探しのページは一時的にアクセスできない状況にあるか、移動、もしくは削除された可能性があります。
        </SubMessage>
        <SubMessage>
          ページ下部より目的のページをご選択ください。
        </SubMessage>
      </InnerMedium>
    </Section>
  </Layout>
)

export default NotFoundPage
